<template>
  <div class="content pb-6">
    <h1>Getting Started</h1>

    <p>
      The <b>Tax ID Pro</b> API makes it easy to validate over 200 different tax ID
      formats for over 100 countries. The API returns JSON-encoded responses, and uses
      standard HTTP response codes.
    </p>
    <br />
    <h5>Step 1: Create an Account</h5>
    <p>
      Sign up for a
      <router-link :to="{ name: 'Signup' }">free trial</router-link> account. No credit
      card is required, and you'll get 100 free tax ID validations and 10 VAT lookups to
      test your implementation. If you need more for testing, just
      <router-link :to="{ name: 'Contact' }">contact us</router-link>!
    </p>
    <p>
      Once you've created an account, navigate to the developer tools and generate an API
      key. API keys are randomly generated. Treat your API key like a password and keep it
      secure.
    </p>
    <br />

    <h5>Step 2: Validate a Tax ID Number</h5>

    Using the tool of your choice (you may be interested in using
    <a href="https://www.postman.com/">Postman</a> or
    <a href="https://marketplace.visualstudio.com/items?itemName=humao.rest-client">
      VS Code REST Client
    </a>
    for experimentation) set up your first request. We are going to validate a a Canadian
    individual tax ID number (Social Insurance Number or SIN). Note that we are
    authorizing this request by including the API key in the Authorization header. There
    are a
    <router-link :to="{ name: 'Authorization' }"
      >variety of ways to authorize</router-link
    >
    your request, but using the HTTP header is the recommended approach. <br /><br />

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      <span class="has-text-success">GET</span>
      https://api.taxid.pro/validate?country=ca&amp;tin=36574261&amp;type=individual
      <br />
      Authorization: PUT_YOUR_API_KEY_HERE
    </div>
    <br />

    <h5>Invalid Response</h5>
    <p>
      If you used the above request and query parameters, you should get a response
      stating that it is not a valid Social Insurance Number because it did not have the
      correct number of digits.
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "valid": <span class="has-text-danger">false</span>,<br />
        "message": "Social Insurance Number should have 9 digits."
      </div>
      }
    </div>
    <br />
    <h5>Corrected Input</h5>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      <span class="has-text-success">GET</span>
      https://api.taxid.pro/validate?country=ca&amp;tin=365742618&amp;type=individual
      <br />
      Authorization: PUT_YOUR_API_KEY_HERE
    </div>
    <br />
    <h5>Valid Response</h5>

    <p>
      The Social Insurance Number is now valid because it has the correct number of
      digits, and it also passes the checksum test. When a tax ID number is valid, the
      message property is always an empty string.
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "valid": <span class="has-text-success">true</span>,<br />
        "message": ""
      </div>
      }
    </div>

    <br />
    <h5>Step 3: Build Your Implementation</h5>
    <p>
      Depending on your implementation, parts of the above query string may need to be
      static or dynamic. Building your implementation is just a matter of assembling the
      query string and making a request to the Tax ID Pro API. To learn about all of the
      available query parameters, visit the
      <router-link :to="{ name: 'Validation' }">Tax ID Validation page</router-link>. To
      learn how to make VAT lookup requests, visit the
      <router-link :to="{ name: 'Lookup' }">VAT lookup page</router-link>.
    </p>
    <p>
      To protect your API key, we recommend proxying your requests through a private
      server. Embedding your API key in client-side JavaScript will expose it to the
      general public, where it could be stolen and subject to abuse.
    </p>
  </div>
</template>

<style scoped>
.param {
  font-family: monospace;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
.param-note {
  font-family: open-sans, sans-serif;
  font-weight: bold;
  color: #aaa;
  font-size: 0.75rem;
}
.value {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #f5f5f5;
  font-size: 0.75rem;
  padding: 2px 4px 1px;
  font-family: monospace;
}
</style>

<script>
export default {
  name: 'Validation',
  mounted() {
    if (this.$route.hash) location.hash = this.$route.hash
    else window.scrollTo(0, 0)
  },
}
</script>